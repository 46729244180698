<template>
    <div>
        <AboutUs :item="page" />
        <v-container>
            <v-row justify="center" >
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Nome"
                        counter="30"
                        filled></v-text-field>

                    <v-text-field
                        label="Apelido"
                        counter="30"
                        filled></v-text-field>

                    <v-textarea
                        filled
                        counter="200"
                        label="Mensagem"></v-textarea>

                    <v-text-field
                        label="Email"
                        type="email"
                        filled></v-text-field>
                    
                    <v-btn color="primary"><span class="black--text">Enviar</span></v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import AboutUs from '../components/AboutUs';
import { PAGE_KEYS } from '../utils';
import { /*mapGetters,*/ mapActions } from 'vuex';
export default {
    data: () => ({
        page: {
            banner: null,
            text: null
        },
        loadingPage: false,
    }),
    created() {
        //this.loadAbout({ code: 'quem-somos' });
        this.loadingPage = true;
        this.loadPage({ key : PAGE_KEYS.contacto }).then((function (page) {
            this.page = page;
        }).bind(this)).catch(() => {
        }).finally(() => this.loadingPage = false)
    },
    //computed: mapGetters(['projects']),
    //methods: mapActions(['loadAbout']),
    methods: { 
        ...mapActions(['loadPage']),
    },
    components: {
        AboutUs,
    }    
}
</script>